import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { RedocStandalone } from 'redoc';
import './App.css';

import { Layout, Menu  } from 'antd';

import lgcCore from './apis/lgc.core.json';
import msQuoteProvider from './apis/ms.quote-provider.json';

const { Header, Content, Footer } = Layout;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CustomLayout />} />
        <Route path="lgc-core" element={<LgcCore />} />
        <Route path="ms-quote-provider" element={<MsQuoteProvider />} />
      </Routes>
    </BrowserRouter>
  );
}

function CustomLayout() {
  return (
    <Layout>
      <Header>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item key="1">
            <Link to="/lgc-core">Legacy Core</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/ms-quote-provider">Quote Provider Api Reference</Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content className="site-layout" style={{ padding: '0', marginTop: 64 }}>
        
      </Content>
      <Footer style={{ textAlign: 'center' }}>Api Reference | Smartenvios ©2022</Footer>
    </Layout>
  );
}

function LgcCore() {
  return (
    <Layout>
      <Header>
        <Menu theme="dark" mode="horizontal">
        <Menu.Item key="1">
            <Link to="/lgc-core">Legacy Core Api Reference</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/ms-quote-provider">Quote Provider Api Reference</Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content className="site-layout">
        <RedocStandalone
          spec={lgcCore}
          options={{
            nativeScrollbars: true,
            theme: { 
              colors: { primary: { main: '#161d40' } },
              rightPanel: { backgroundColor: '#001628' },
              logo: { gutter: '10' }
            },
          }}
        />;
      </Content>
      <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
    </Layout>
  );
}

function MsQuoteProvider() {
  return (
    <Layout>
      <Header>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item key="1">
            <Link to="/lgc-core">Legacy Core Api Reference</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/ms-quote-provider">Quote Provider Api Reference</Link>
          </Menu.Item>
        </Menu>
      </Header>
      <Content className="site-layout">
        <RedocStandalone
          spec={msQuoteProvider}
          options={{
            nativeScrollbars: true,
            theme: { 
              colors: { primary: { main: '#161d40' } },
              rightPanel: { backgroundColor: '#001628' },
            },
          }}
        />;
      </Content>
      <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
    </Layout>
  );
}